import React, {useState} from 'react';
import styled from 'styled-components';
import Image from '../../../Components/Image';
import {Tab} from '../../../Widgets';
import ProductFile from '../ProductFile';
import {Center} from '../../../Components/Layout/ContentWrapper';
const config = require('../../../data.json');

const {TabBar, TabItem, TabContent} = Tab;

const TABS = {
  INTRO: 0,
  SPEC: 1,
  NOTICE: 2,
  TEMPLATE: 3,
};

export default function BottomSection(props) {
  const [index, setIndex] = useState(TABS.INTRO);
  const {product} = props;
  if (!product) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: '#f0f0f0',
        width: '100vw',
        paddingBottom: 30,
      }}>
      <Center>
        <Wrapper>
          <TabBar>
            {['產品介紹', '產品規格', '完稿須知', '刀模下載'].map(
              (tab, idx) => (
                <TabItem
                  key={idx}
                  selected={idx === index}
                  onClick={() => setIndex(idx)}>
                  {tab}
                </TabItem>
              ),
            )}
          </TabBar>

          <TabContent>
            {index === TABS.INTRO && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'intro')
                  .map((img, idx) => (
                    <Image
                      alt={`${product.name} 產品介紹 - ${idx}`}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
                {product.id === 327 && (
                  <div>
                    <h2>流程步驟:</h2>
                    <h3>下訂打樣</h3>
                    <p>
                      <strong>Step1:</strong>{' '}
                      在商品的打樣頁面下單，輸入打樣款式所需數量，並在訂單備註後續大貨製作數量。
                    </p>
                    <h3>等待寄出</h3>
                    <p>
                      <strong>Step2:</strong>{' '}
                      打樣商品製作完成後，理想會留存一份打樣，並寄出一份樣品給客戶。
                    </p>
                    <h3>確認樣品</h3>
                    <p>
                      <strong>Step3:</strong>{' '}
                      收到且確認打樣商品無誤後，自行留存無需寄回打樣商品。正式製作請至商品頁面下訂大貨，並在訂單備註告知打樣訂單編號。
                    </p>

                    <h2>打樣的規則:</h2>
                    <ul>
                      <li>
                        打樣訂單下單後會一次製作兩份：一份寄出給客戶，一份為理想留存以利後續大貨製作對照用。留存於理想的打樣品不會主動歸還給客戶，如客戶需要打樣品寄回請於訂單內備註，將會與大貨一同寄出。
                      </li>
                      <li>
                        如對商品打樣效果有疑慮或有調整圖檔請務必重新再次下單，請勿打樣後調整檔案而無再次打樣直接進入大貨製作，恕無法以原先打樣之商品狀態與調整後無打樣之商品狀態做為對照。
                      </li>
                    </ul>

                    <h2>打樣的物件留存時間:</h2>
                    <ul>
                      <li>
                        理想留存打樣品的時間為經訂單完成後起算三個月，時效過後恕無責任協助保管打樣對照品，請客戶自行留存下單的打樣品。
                      </li>
                      <li>
                        如原有訂單需再次下訂也請重新再打樣，因機器會固定校正，顏色會有差異。
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            {index === TABS.SPEC && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'spec')
                  .map((img, idx) => (
                    <Image
                      alt={`${product.name} 產品規格 - ${idx}`}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
              </div>
            )}

            {index === TABS.NOTICE && (
              <div className="image-container">
                {product.images
                  .filter((image) => image.name === 'notice')
                  .map((img, idx) => (
                    <Image
                      alt={`${product.name} 完稿須知 - ${idx}`}
                      key={idx}
                      style={{width: '100%'}}
                      src={img.image}
                      linkto={img.link}
                    />
                  ))}
              </div>
            )}

            {index === TABS.TEMPLATE && product.files && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: config.productFileDirection,
                  flexWrap: 'wrap',
                }}>
                {product.files.map((f, idx) => (
                  <ProductFile file={f} key={idx} index={idx} />
                ))}
              </div>
            )}
          </TabContent>
        </Wrapper>
      </Center>
    </div>
  );
}

const Wrapper = styled.div`
  border: 1px solid #ddd;
  background-color: white;

  & .image-container {
    margin: 0 auto;
    max-width: 860px;
  }
`;
